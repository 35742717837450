import React from 'react';
import Helmet from 'react-helmet';
import {graphql, useStaticQuery} from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import settings from '../../settings';
import Layout from '../../components/Layout';
import Banner from '../../components/Banner';
import SectionImageLeft from '../../components/SectionImageLeft';
import SectionImageRight from '../../components/SectionImageRight';
import SectionLists from '../../components/SectionLists';
import TextHeadingParagraph from '../../components/TextHeadingParagraph';
import Carousel from '../../components/Carousel';
import Label from '../../components/Carousel/SlideLabel';
import ListBullet from '../../components/ListBullet';
import '../../styles/pages/landscaping.scss';



//	https://bloximages.chicago2.vip.townnews.com/lompocrecord.com/content/tncms/assets/v3/editorial/a/b0/ab056f28-b52e-5ff1-afe8-4d2a114b7258/5b84238bbb405.image.jpg?resize=1700%2C1133
//	https://ict4water.eu/wp-content/uploads/2018/05/ict4water-wave-header-image-scaled.jpg
//	http://www.mdblandscapes.co.uk/garden-drainage.html
//	https://lmchouston.com/how-can-standing-water-affect-my-property

//	------------------------------
//	Amazing Article about Drainage
//	------------------------------
//	https://www.campbellferrara.com/blog/proper-drainage-important-landscaping/

export default function() {
	const section_height = '440px';
	const styles_li = {
		lineHeight: '40px',
	};

	const {
			imageMobile_mowing_01,			imageDesktop_mowing_01,
			imageMobile_mowing_02,			imageDesktop_mowing_02,
			imageMobile_drainage_01,		imageDesktop_drainage_01,
			imageMobile_drainage_02,		imageDesktop_drainage_02,
			imageMobile_turf_01,			imageDesktop_turf_01,
			imageMobile_turf_02,			imageDesktop_turf_02,
			imageMobile_grading_01,			imageDesktop_grading_01,
			imageMobile_grading_02,			imageDesktop_grading_02,
			imageMobile_grading_03,			imageDesktop_grading_03,
			imageMobile_grading_04,			imageDesktop_grading_04,
			imageMobile_grading_05,			imageDesktop_grading_05,
			imageMobile_grading_06,			imageDesktop_grading_06,
			imageMobile_grassHeader_01,		imageDesktop_grassHeader_01,
			imageMobile_drainageHeader_01,	imageDesktop_drainageHeader_01,
			imageMobile_gradingHeader_01,	imageDesktop_gradingHeader_01,
	} = useStaticQuery(
		graphql`
		  query {

			imageMobile_mowing_01: file(relativePath: { eq: "pages/landscaping/mowing.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_mowing_01: file(relativePath: { eq: "pages/landscaping/mowing.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_mowing_02: file(relativePath: { eq: "pages/landscaping/mowing.02.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_mowing_02: file(relativePath: { eq: "pages/landscaping/mowing.02.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_drainage_01: file(relativePath: { eq: "pages/landscaping/drainage.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320, maxHeight:220, cropFocus:SOUTH) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_drainage_01: file(relativePath: { eq: "pages/landscaping/drainage.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:600, maxHeight:400, cropFocus:SOUTH) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_drainage_02: file(relativePath: { eq: "pages/landscaping/istock.drainage.02.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320, maxHeight:400, cropFocus:CENTER) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_drainage_02: file(relativePath: { eq: "pages/landscaping/istock.drainage.02.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640, maxHeight:400, cropFocus:CENTER) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_turf_01: file(relativePath: { eq: "pages/landscaping/turf.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_turf_01: file(relativePath: { eq: "pages/landscaping/turf.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_turf_02: file(relativePath: { eq: "pages/landscaping/turf.02.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_turf_02: file(relativePath: { eq: "pages/landscaping/turf.02.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_grading_01: file(relativePath: { eq: "pages/landscaping/istock.lawnLeveling.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_grading_01: file(relativePath: { eq: "pages/landscaping/istock.lawnLeveling.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_grading_02: file(relativePath: { eq: "pages/landscaping/istock.lawnLeveling.02.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_grading_02: file(relativePath: { eq: "pages/landscaping/istock.lawnLeveling.02.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_grading_03: file(relativePath: { eq: "pages/landscaping/istock.lawnLeveling.03.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_grading_03: file(relativePath: { eq: "pages/landscaping/istock.lawnLeveling.03.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_grading_04: file(relativePath: { eq: "pages/landscaping/istock.lawnLeveling.04.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_grading_04: file(relativePath: { eq: "pages/landscaping/istock.lawnLeveling.04.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_grading_05: file(relativePath: { eq: "pages/landscaping/istock.lawnLeveling.05.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_grading_05: file(relativePath: { eq: "pages/landscaping/istock.lawnLeveling.05.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_grading_06: file(relativePath: { eq: "pages/landscaping/istock.lawnLeveling.06.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_grading_06: file(relativePath: { eq: "pages/landscaping/istock.lawnLeveling.06.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_grassHeader_01: file(relativePath: { eq: "pages/landscaping/istock.grass.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320, maxHeight:400, cropFocus:SOUTH) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_grassHeader_01: file(relativePath: { eq: "pages/landscaping/istock.grass.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:1024, maxHeight:400, cropFocus:SOUTH) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_drainageHeader_01: file(relativePath: { eq: "pages/landscaping/istock.water.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320, maxHeight:400, cropFocus:CENTER) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_drainageHeader_01: file(relativePath: { eq: "pages/landscaping/istock.water.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:1024, maxHeight:400, cropFocus:CENTER) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_gradingHeader_01: file(relativePath: { eq: "pages/landscaping/istock.dirt.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_gradingHeader_01: file(relativePath: { eq: "pages/landscaping/istock.dirt.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

		  }
		`
  );

	const image_sources	=	{

		grading_01	:	[
			imageMobile_grading_01.childImageSharp.fluid,
			{
			...imageDesktop_grading_01.childImageSharp.fluid,
				media: `(min-width: 768px)`,
			},
		],

		grading_02	:	[
			imageMobile_grading_02.childImageSharp.fluid,
			{
			...imageDesktop_grading_02.childImageSharp.fluid,
				media: `(min-width: 768px)`,
			},
		],

		grading_03	:	[
			imageMobile_grading_03.childImageSharp.fluid,
			{
			...imageDesktop_grading_03.childImageSharp.fluid,
				media: `(min-width: 768px)`,
			},
		],

		grading_04	:	[
			imageMobile_grading_04.childImageSharp.fluid,
			{
			...imageDesktop_grading_04.childImageSharp.fluid,
				media: `(min-width: 768px)`,
			},
		],

		grading_05	:	[
			imageMobile_grading_05.childImageSharp.fluid,
			{
			...imageDesktop_grading_05.childImageSharp.fluid,
				media: `(min-width: 768px)`,
			},
		],

		grading_06	:	[
			imageMobile_grading_06.childImageSharp.fluid,
			{
			...imageDesktop_grading_06.childImageSharp.fluid,
				media: `(min-width: 768px)`,
			},
		],

		grassHeader_01	:	[
			imageMobile_grassHeader_01.childImageSharp.fluid,
			{
			...imageDesktop_grassHeader_01.childImageSharp.fluid,
				media: `(min-width: 768px)`,
			},
		],

		drainageHeader_01	:	[
			imageMobile_drainageHeader_01.childImageSharp.fluid,
			{
			...imageDesktop_drainageHeader_01.childImageSharp.fluid,
				media: `(min-width: 768px)`,
			},
		],

		gradingHeader_01	:	[
			imageMobile_gradingHeader_01.childImageSharp.fluid,
			{
			...imageDesktop_gradingHeader_01.childImageSharp.fluid,
				media: `(min-width: 768px)`,
			},
		],

	};

	return (
		<Layout>
			<Helmet>
				<title>Landscaping • Blackstone VT</title>
				<link rel="canonical" href={`${settings.host.url}/landscaping`} />
				<meta
					name="description"
					content="Authentic and premium quality landscaping in Southern Vermont. Working examples of landscape design, grass mowing, and property drainage."
				/>
			</Helmet>
			<div style={{paddingBottom:'50px'}} id="mowing">
				<Banner bgImageSources={image_sources.grassHeader_01} bgColor="White" styleBgMask={{opacity:'0.2'}} shadowBottom={true} styleBg={{backgroundPosition:'bottom'}}>Mowing</Banner>

				<SectionImageRight
					imageMobile={imageMobile_mowing_01}
					imageDesktop={imageDesktop_mowing_01}
					imageAlt="Mowed Meadow"
					height="400px">
					<TextHeadingParagraph heading="Do it right">
						Balanced with your own desires, our mowing style and method is assessed to fit the needs of your specific property.
						We mow public & private properties,
						lawns & meadows,
						residential & commercial,
						arenas & sports fields.
						Each property is cared for uniquely.
					</TextHeadingParagraph>
				</SectionImageRight>

				<SectionImageLeft
					imageMobile={imageMobile_mowing_02}
					imageDesktop={imageDesktop_mowing_02}
					imageAlt="Healthy Mowed Lawn"
					height="400px">
					<TextHeadingParagraph heading="Lawn Health">
						Most providers view mowing too simply and we are here to improve that.
						Our staff realizes caretaking for your grass can mean more than simply 'mowing'.
						Timing your mow and mowing to the right height is important.
						Grass has an optimum height for retaining water and self-shading.
						Too short of a mow will cause browning / burning.
						Mowing when still wet will cause clumping.
						Variance in mowing patterns protects against ruts.
						We pay attention to all of it so you don't have to.
					</TextHeadingParagraph>
				</SectionImageLeft>

			</div>
			<div style={{paddingBottom:'50px'}} id="drainage">
				<Banner bgImageSources={image_sources.drainageHeader_01} bgColor="LightSkyBlue" styleBgMask={{opacity:'0.3'}} shadowBottom={true}>Drainage</Banner>

				<SectionImageRight
					imageMobile={imageMobile_drainage_01}
					imageDesktop={imageDesktop_drainage_01}
					imageAlt="Installation of French Drain in Lawn"
					height="400px">
					<TextHeadingParagraph heading="Soil Stability">
						Too eagerly beginning your landscaping without considering existing water issues will surely cause problems for you after the fact.
						At best, the solution could be simply redirecting water with gutters or downspouts.
						More often, the issue is so major that you’re forced remove all existing work and redo the entire project.
						Our consultants help you make the right choice.
					</TextHeadingParagraph>
				</SectionImageRight>

				<hr/>
				<SectionLists className="pb-3" height={section_height} style={{
					color: settings.color.font.dark,
				}}>
					<div className="col-12 col-lg align-self-center"
						style={{
							fontSize: '22px',
							fontWeight: '100',
							whiteSpace: 'nowrap',
						}}
					>
						<h4 className="text-center">Drainage Solutions</h4>
						<hr className="my-4" style={{
							backgroundColor: settings.color.bg.light,
							height: '1px',
							maxWidth: '130px',
						}}/>
					</div>
					<div className="col-12 col-lg pl-md-5 pl-lg-0 align-self-center page__drainagesolutions"
						style={{
							fontSize: '16px',
							fontWeight: '100',
						}}
					>
						<ul style={{
							listStyle: 'none',
							marginBottom: '0',
							padding: '0',
						}}>
							<li style={styles_li}><ListBullet/>French drains to direct water away from the home</li>
							<li style={styles_li}><ListBullet/>Area drains to keep water from pooling up in low spots</li>
							<li style={styles_li}><ListBullet/>Channel drains to keep patio runoff from ruining the lawn or garden</li>
							<li style={styles_li}><ListBullet/>A sump pump system for any particularly bad areas</li>
							<li style={styles_li}><ListBullet/>Building a creek bed or water feature to channel runoff</li>
							<li style={styles_li}><ListBullet/>Choosing more water-tolerant trees, plants and shrubbery</li>
						</ul>
					</div>
				</SectionLists>
				<hr/>

				<SectionImageLeft
					imageMobile={imageMobile_drainage_02}
					imageDesktop={imageDesktop_drainage_02}
					imageAlt="Flooded Lawn"
					height="400px">
					<TextHeadingParagraph heading="Protect Investments">
						Without proper drainage, you could find water quickly begin to pool in different areas, often with the result of killing your lawn, trees, bushes or other plants.
						Worse still, poor drainage can eventually cause structural damage and crack foundations should the water pool up next to the home.
						Improper drainage can also lead to soil erosion or simply end up costing you money in the form of wasted irrigation water.
					</TextHeadingParagraph>
				</SectionImageLeft>

			</div>

			<div style={{paddingBottom:'50px'}} id="grading">
				<Banner bgImageSources={image_sources.gradingHeader_01} bgColor="White" styleBg={{backgroundPosition:'50% 0%',backgroundSize:'500px 420px',backgroundRepeat:'repeat'}} styleBgMask={{opacity:'0.1'}} shadowBottom={true}>Grading</Banner>

				<SectionLists className="py-5" height={section_height} style={{
					color: settings.color.font.dark,
				}}>
					<div className="col-12 col-lg align-self-center"
						style={{
							fontSize: '22px',
							fontWeight: '100',
							whiteSpace: 'nowrap',
						}}
					>
						<h4 className="text-center">Lawn Leveling</h4>
						<hr className="my-4" style={{
							backgroundColor: settings.color.bg.light,
							height: '1px',
							maxWidth: '130px',
						}}/>
					</div>
					<div className="col-12 col-lg pl-md-5 pl-lg-0 align-self-center page__landscaping"
						style={{
							fontSize: '16px',
							fontWeight: '100',
						}}
					>
						<ul style={{
							listStyle: 'none',
							marginBottom: '0',
							padding: '0',
						}}>
							<li style={styles_li}><ListBullet/>A great lawn is often the first attribute noticed about your property</li>
							<li style={styles_li}><ListBullet/>Leveling is a <strong>must</strong> for great lawn appearance</li>
							<li style={styles_li}><ListBullet/>Uneven lawns give the appearance of an uneven mow</li>
							<li style={styles_li}><ListBullet/>Level lawns reduce safety risks & walking hazards</li>
							<li style={styles_li}><ListBullet/>Proper level grading keeps water away from your foundation</li>
							<li style={styles_li}><ListBullet/>Future & existing landscape features can be at serious risk without thoughtful leveling</li>
						</ul>
					</div>
				</SectionLists>

				<hr/>

				<Carousel>

					<BackgroundImage alt="Prep work for a new lawn" fluid={image_sources.grading_01} className="slide_content" ><Label>Prep</Label></BackgroundImage>
					<BackgroundImage alt="Prep work for a new lawn" fluid={image_sources.grading_02} className="slide_content" ><Label>Prep</Label></BackgroundImage>
					<BackgroundImage alt="Prep work to level a lawn" fluid={image_sources.grading_03} className="slide_content" ><Label>Prep</Label></BackgroundImage>
					<BackgroundImage alt="Leveled Lawn" fluid={image_sources.grading_04} className="slide_content" ><Label>Leveled</Label></BackgroundImage>
					<BackgroundImage alt="Leveled Lawn" fluid={image_sources.grading_05} className="slide_content" ><Label>Leveled</Label></BackgroundImage>
					<BackgroundImage alt="Leveled Lawn" fluid={image_sources.grading_06} className="slide_content" ><Label>Leveled</Label></BackgroundImage>

				</Carousel>
			</div>

			<div style={{paddingBottom:'50px'}} id="turf">
				<Banner shadowBottom={true}>Artificial</Banner>

				<SectionImageRight
					imageMobile={imageMobile_turf_02}
					imageDesktop={imageDesktop_turf_02}
					imageAlt="Artificial Turf Backyard"
					height="400px">
					<TextHeadingParagraph heading="Turf">
						Mowing, watering, weeding, aeration, & fertilizing can be a lot of work.
						Where it can be impossible to keep grass alive in high traffic areas, Turf doesn't need rest.
						If you don't desire a caretaker for lawn maintenance or aren't at your property enough to do it yourself, Turf may be a solution.
					</TextHeadingParagraph>
				</SectionImageRight>

				<SectionImageLeft
					imageMobile={imageMobile_turf_01}
					imageDesktop={imageDesktop_turf_01}
					imageAlt="Artificial Turf Backyard"
					height="400px">
					<TextHeadingParagraph heading="Earth & Air">
						Surprisingly, artificial solutions can be more environmentally friendly than natural grass.
						Did you know a walk-behind mower emits as much pollution in one hour as 11 cars or that a riding mower emits as much as 34 cars?
						Turf eliminates or reduces noise & noxious emissions from maintenance equipment and mowers.
					</TextHeadingParagraph>
				</SectionImageLeft>

			</div>


		</Layout>
	);
}

