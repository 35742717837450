import React from "react";
import Reveal from '../Reveal';

export default function({height,children,style,className}) {

	const classes = new Set([
		'container','py-6'
	]);
	if( className ){
		classes.delete('py-6')
		className.split(' ').forEach(function(_class){
			classes.add(_class);
		});
	}//if
	

	return (
		<div style={{
			...style
		}}>
		<Reveal>
			<div className={Array.from(classes).join(' ')} style={{
				position: 'relative',
			}}
			>
				<div className="column" style={{height:'100%',}}>
					<div className="row" style={{height:'100%',}}>
						{children}
					</div>
				</div>
			</div>
		</Reveal>
		</div>
	);
}
