import React from 'react';
import settings from '../../settings';

export default function (){
	return (
		<span style={{
			color: 'transparent',
			fontWeight: '100',
			paddingRight: '10px',
			textShadow: settings.color.bg.light+' 0px -1px 2px',
		}}>|</span>
	);
}